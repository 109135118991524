import React from 'react'
import styled, { css } from 'styled-components'
import { escapeRegExp } from '../../utils'
import { useDarkModeManager } from '../../state/user/hooks'

const StyledInput = styled.input<{ error?: boolean; fontSize?: string; align?: string; isDark?: boolean }>`
  color: ${({ error, theme }) => error && theme.red1};
  color: ${({ theme }) => theme.text1};
  width: 0;
  position: relative;
  font-size: 24px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  outline: none;
  border: none;
  flex: 1 1 auto;
  background-color: ${({ theme }) => theme.bg1};
  background-color: transparent;
  font-size: ${({ fontSize }) => fontSize && fontSize};
  text-align: ${({ align }) => align && align};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.text4};
    color: #ffffff;
  }

  ::placeholder {
    ${({ isDark }) =>
      isDark
        ? css`
            color: ${({ theme }) => theme.text4};
          `
        : css`
            color: #000000;
          `}

`

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

export const Input = React.memo(function InnerInput({
  value,
  onUserInput,
  placeholder,
  ...rest
}: {
  value: string | number
  onUserInput: (string) => void
  error?: boolean
  fontSize?: string
  align?: 'right' | 'left'
} & Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'>) {
  const [isDark] = useDarkModeManager()
  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
      onUserInput(nextUserInput)
    }
  }

  return (
    <StyledInput
      {...rest}
      isDark={isDark}
      value={value}
      onChange={event => {
        enforcer(event.target.value)
      }}
      // universal input options
      inputMode="decimal"
      title="Token Amount"
      autoComplete="off"
      autoCorrect="off"
      // text-specific options
      type="text"
      placeholder={placeholder || '0.0'}
      minLength={1}
      maxLength={79}
      spellCheck="false"
    />
  )
})

export default Input
